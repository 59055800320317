/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


        (function($) {

          $('.careers-openings__list a').on('click', function(e) {
          e.preventDefault();
          $(this).tab('show');
          var theThis = $(this);
          $('.careers-openings__list a').removeClass('active');
          theThis.addClass('active');
          });

          $('.careers-openings__list li:first-child > a').addClass('active');
          $('#openingsTabContent .tab-pane:first-child').addClass('active');

        })(jQuery);
          
        //Toggle Search
        $( ".js-search-overlay-trigger" ).click(function() {
          $( ".search-overlay-wrapper" ).addClass('search-overlay-wrapper--is-open');
          $( 'body' ).addClass('body--search-is-open');
        });
        $( ".js-search-close-trigger" ).click(function() {
          $( ".search-overlay-wrapper" ).removeClass('search-overlay-wrapper--is-open');
          $( 'body' ).removeClass('body--search-is-open');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      (function($) {

        $('[data-toggle=tab]').click(function(){
          if ($(this).parent().hasClass('active')){
            $($(this).attr("href")).toggleClass('active');
          }
        })


        $('.thumbs-wrap__slider').owlCarousel({
          loop:true,
          dots:false,
          responsive:{
            0:{
                items:1,
                nav:true
            },
            600:{
                items:3,
                nav:false
            },
            981:{
                items:4,
                nav:true,
                loop:false
            }
        }
        });
      })(jQuery);

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
